export const IdentificationEn = () => {
  return (
    <>
      <h1 className="main__title">Identification (Verification)</h1>
      <h2>Verification — Why Is It Needed and How to Do It?</h2>
      <p>
        Player verification at a bookmaker involves confirming the player's
        identity by providing an official document (most often a passport).
        Many newcomers registering at bookmaker websites for the first time do
        not consider this procedure reasonable or safe and choose an
        alternative—creating a bookmaker account without a passport.
      </p>
      <p>
        In all legal bookmakers, verification is a mandatory procedure. This is
        due to significantly reducing risks for both the player and the
        bookmaker itself.
      </p>
      <h2>Which Verification Methods Are Available?</h2>
      <p>
        The bookmaker offers clients three ways to verify their account on the
        site:
      </p>
      <ol>
        <li>
          The standard method involves sending scanned copies of passport data
          (the first page/spread of the passport and the page with the place of
          registration). The user first registers on the website, then logs in
          with the provided credentials, goes to the personal account page, and
          then to the “Settings” section. All scans must be of high quality;
          otherwise, the security service may not only refuse to process the
          request but also block the account altogether if there is suspicion of
          fraudulent activity. Passing verification protects the bookmaker from
          fraud and multiple accounts, and it ensures fair play and timely
          payouts for the bettor.
        </li>
        <li>
          The third method: a video call via Skype. The bettor needs to contact
          the bookmaker’s representative, arrange a convenient time, and speak
          with a bookmaker employee. During the conversation, it is necessary to
          have an identity document ready to show upon request.
        </li>
      </ol>
      <p>
        If the player fails to complete verification, their withdrawal limits
        will be significantly restricted. For a non-verified account, the
        monthly financial turnover must not exceed 200,000 rubles. A single
        transaction cannot exceed 60,000 rubles.
      </p>
      <h2>Safety of Providing Passport Data to a Bookmaker</h2>
      <p>
        If we’re talking about illegal (unlicensed) bookmakers, then providing
        such data is NOT safe!
      </p>
      <p>
        Offshore companies do not guarantee the confidentiality of users’
        personal data or payment details, nor do they guarantee that the data
        will not be passed on to third parties.
      </p>
      <p>
        In the event of unpleasant situations, a player at an illegal bookmaker
        will have nowhere to turn. There are no company representatives in
        Russia; the company is registered far away, so contacting them or making
        any demands is practically impossible. Moreover, there are no grounds
        for any demands.
      </p>
      <div className="color-pink">
        <p>
          Important! Safe verification that requires providing a bettor’s
          passport data is only possible if the bookmaker legally operates in
          Russia under the permission of the Federal Tax Service (FNS).
          All data will be stored in encrypted form on TsUPIS (Center for
          Accounting for Transfers of Interactive Bets) servers.
        </p>
      </div>
      <h2>Which Sports Are Supported/Will Be Supported by the Workbet Scanner</h2>
      <ul>
        <li>Football</li>
        <li>Basketball</li>
        <li>Tennis</li>
        <li>Hockey</li>
        <li>Volleyball</li>
        <li>Esports</li>
      </ul>
    </>
  )
}