export const TaxRequestsEn = () => {
  return (
    <>
      <h1 className="main__title">TAX REQUESTS</h1>
      <h2>Question</h2>
      <p>Who is required to pay taxes, and under what circumstances?</p>
      <h2>Briefly</h2>
      <p>
        No tax is paid on winnings up to 15,000 rubles in legal (licensed)
        bookmakers. Everything else is taxable.
      </p>
      <h2>In Detail</h2>
      <p>
        If the bookmaker is a resident (legal) entity, then they, as the tax
        agent, calculate and pay personal income tax (NDFL) on each winning (in
        accordance with Article 214.7 of the Tax Code of the Russian
        Federation). However, under the new federal law, this applies only to
        winnings of 15,000 rubles or more. That is, if the win is more than
        15,000 rubles, the bookmaker subtracts the stake from the winnings and
        withholds 13% tax on the difference for Russian tax residents, or 30%
        for non-residents.
      </p>
      <p>
        If the win is less than 15,000 rubles, the bookmaker pays the full
        amount to the client and ceases to be the client’s tax agent. The bettor
        must file a tax return independently at the end of the year.
      </p>
      <p>
        If the bookmaker is a non-resident (offshore), the bettor is responsible
        for keeping records and calculating personal income tax on their own.
        You must pay the tax yourself and personally submit a tax return by the
        deadline established by law.
      </p>
    </>
  )
}