export const FaqEn = () => {
  return (
    <>
      <h1 className="main__title">FAQ (Frequently Asked Questions)</h1>
      <h2>Who typically uses surebet scanners?</h2>
      <p>
        These programs are generally used by bettors who want to make serious
        money on sports betting.
      </p>
      <h2>Where is it more profitable to use scanners: prematch or live?</h2>
      <p>
        Each option has its advantages and drawbacks. Live scanners yield higher
        profits, but arbitrage situations in prematch have a longer “lifetime.”
      </p>
      <h2>
        Does the surebet scanner indicate how much to bet on different outcomes
        to guarantee a win regardless of the result?
      </h2>
      <p>
        No, the service only finds and displays potential surebets. Bettors can
        calculate the required amounts using an arbitrage calculator.
      </p>
      <h2>How surebet services work</h2>
      <p>
        Surebet programs analyze the odds of different bookmakers, compare
        coefficients, and then provide the bettor with a comprehensive summary
        of available arbitrage situations. This gives bettors an edge over the
        bookmakers.
      </p>
      <p>
        These programs operate at high speed and update information promptly,
        enabling you to “catch” arbitrage situations that might only last a
        couple of minutes.
      </p>
      <h2>There are several types of surebet scanners:</h2>
      <ul>
        <li>
          <b>Prematch</b>. These services specialize in finding suitable
          arbitrage situations among events that have not yet started. A major
          drawback of this approach is a significantly smaller selection of
          outcomes in prematch.
        </li>
        <li>
          <b>Live</b>. Used to detect arbitrage situations in real-time (live
          betting). The main advantage is that it is harder for a bookmaker to
          detect a live surebet, since they may not have time to check all their
          competitors. A disadvantage is that bookmakers tend to increase the
          margin on live bets.
        </li>
        <li>
          <b>Universal</b>. These scanners work both in prematch and live,
          allowing you to find more arbitrage situations. However, universal
          programs are more expensive than other surebet services.
        </li>
      </ul>
      <p>
        Each service sets its own subscription price for users, depending on the
        available features and the quality of its scanning.
      </p>
      <h2>What is sports arbitrage?</h2>
      <p>
        Sports arbitrage (surebet) is a situation in which the difference in odds
        across two or more bookmakers allows you to place a bet on each
        mutually exclusive outcome at different bookmakers and still make a
        profit regardless of the result. Such an opportunity may arise due to
        competition among bookmakers over their odds, differing assessments of
        probabilities, or mistakes in setting the odds.
      </p>
      <div className="color-pink">
        <p className="text-align--center font-size--18">
          So, how do you find a surebet and place your bets correctly?
          <br />
          That’s where our Workbet ecosystem comes in to help you!
        </p>
      </div>
    </>
  )
}