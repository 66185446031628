import { Link } from "react-router-dom"

export const TermsEn = () => {
  return (
    <>
      <h1 className="main__title">User Agreement</h1>
      <p>Effective Date: November 1, 2022</p>
      <p>
        This User Agreement (hereinafter referred to as the "Agreement") is a
        contract between you ("You" or "User") and the owners of the website
        workbet.info ("We" or "Us"). You must read, agree to, and accept all the
        terms contained in this Agreement in order to use the website located at{" "}
        <Link to="/">workbet.info</Link>, the related mobile website, and the
        mobile application, owned and operated by us (hereinafter collectively
        referred to as the "Service").
      </p>
      <p>
        This Agreement includes and thereby incorporates the following: the terms
        used in the Agreement, the subject matter of the Agreement, the rights
        and obligations of the parties, account registration; profile, pricing
        and payment procedures, disclaimer of warranties, links to third-party
        websites, limitation of liability, dispute resolution, indemnification,
        intellectual property, mobile devices, and final provisions.
      </p>
      <p>
        Under the terms set forth in this document, we may, at our sole
        discretion, change this Agreement at any time by posting a revised
        version on the Service. If a material change includes an increase in the
        fees we charge, we will provide notice of the changes 30 days in
        advance; all other changes may be made without prior notice. Any changes
        to this Agreement become effective upon posting unless an effective date
        is specified or from the specified effective date.
      </p>
      <p>
        Your continued use of the Service after the new version of this Agreement
        takes effect signifies your acceptance of the new version.
      </p>
      <p>
        YOU UNDERSTAND THAT BY USING THE SERVICE AFTER THE EFFECTIVE DATE OF THIS
        AGREEMENT, YOU FULLY AGREE TO ITS TERMS. IF, AFTER THE EFFECTIVE DATE OF
        THIS AGREEMENT, YOU DO NOT ACCEPT ITS TERMS IN FULL, YOU MUST NOT USE
        THE SERVICE.
      </p>
      <h2>1. Terms Used in the Agreement</h2>
      <p>
        1.1. The following words, expressions, and abbreviations, unless the
        context requires otherwise, have the following meaning:
      </p>
      <p>
        <b>Agreement</b> – this user agreement (public offer) between the User
        and us, the current version of which is located at{" "}
        <Link to="/profile/account">https://workbet.info/profile/account</Link>
        <br />
        <b>Account</b> – the account you create when you register on the Service.
        <br />
        <b>Service</b> – all services available on the website, on the related
        mobile website, and the mobile application provided at{" "}
        <Link to="/">https://workbet.info</Link>, owned and controlled by us.
        <br />
        <b>User</b> – a user who creates an Account on the website and is
        authorized to use the Service.
      </p>
      <h2>2. Subject Matter of the Agreement</h2>
      <p>
        2.1. This Agreement is an official offer (public offer) and contains all
        the essential terms for the provision of the Service.
        <br />
        2.2. By registering an account on the Service or clicking the button to
        accept the Agreement when prompted by the Service, you accept this
        Agreement as valid on the date of your Account registration or on the
        date you click the button to accept the Agreement. The registration of
        your Account confirms that you can receive, download, and print this
        Agreement in electronic form. You are responsible for the accuracy of
        the information you provide upon registration.
        <br />
        2.3. The Service automatically scans bookmaker odds and provides
        information about arbitrage situations (surebets) resulting from the
        analysis of odds. The information published on the Service is provided
        to the User by subscription according to the selected tariff plan.
        <br />
        2.4. The Service is not a financial instrument; it does not provide
        services for managing funds raised from third parties, nor is it a
        bookmaker or a gambling organizer.
      </p>
      <h2>3. Rights and Obligations of the Parties</h2>
      <p>
        3.1. We undertake to:
        <br />
        3.1.1. Provide services in accordance with the chosen tariff plan.
        <br />
        3.1.2. Publish reports related to the Service and any changes to payment
        fees on the Service, and send them by email if necessary.
      </p>
      <p>
        3.2. The User undertakes to:
        <br />
        3.2.1. Independently verify the reliability of information provided by
        the Service immediately before any possible use of such information.
        <br />
        3.2.2. Assume all risks of possible losses or potential profits related
        to the use of information provided by the Service.
        <br />
        3.2.3. Refrain from distributing any information obtained through the
        Service without the Administration’s consent.
        <br />
        3.2.4. Not use any automated tools (bots) to repeatedly read the
        information provided by the Service.
        <br />
        3.2.5. Pay Us for the right to use the Service in accordance with the
        provisions of Section 5.
      </p>
      <p>
        3.3. We have the right to:
        <br />
        3.3.1. Restrict the User’s access to the Service if the User fails to
        comply with this Agreement. If there are grounds to suspect the User of
        violating any provisions of this Agreement, the User’s access to the
        Service may be blocked until the circumstances are clarified.
        <br />
        3.3.2. Add or remove certain data and services at our discretion.
        <br />
        3.3.3. Change the cost of the tariff plans provided to the User. New
        tariff prices become effective from the date of their publication on the
        Service. Changes in the cost of tariff plans do not apply to already
        paid tariff plans.
      </p>
      <p>
        3.4. The User has the right to:
        <br />
        3.4.1. Use the Service only for non-commercial personal use, regardless
        of the chosen tariff plan.
        <br />
        3.4.2. Receive information from us about the Service, the cost, and the
        procedures for using the Service.
        <br />
        3.4.3. Address complaints and suggestions to us to improve the quality of
        the Service.
      </p>
      <h2>4. Account Registration, Profile</h2>
      <p>
        4.1. To use the Service, you must register an Account. When registering
        an Account, you should complete the User profile.
        <br />
        4.2. By registering an Account, you confirm that you are an individual of
        at least 18 years of age, competent to conclude this legally binding
        Agreement.
        <br />
        4.3. You confirm that you are not a citizen or resident of a country in
        which access to or use of the Service is prohibited by current
        legislation, decree, regulation, treaty, or administrative act.
        <br />
        4.3.1. You agree that if the circumstances in your country of residence
        or citizenship change so that the above conditions no longer apply to
        you, you will immediately stop using the Service.
        <br />
        4.3.2. You confirm that you are not a citizen of the United States and
        that you are not located in the United States.
        <br />
        4.4. You agree to provide accurate, precise, and complete information in
        your profile and in all registration forms you submit through the
        Service, or contact us to update your information to keep it accurate,
        precise, and complete.
        <br />
        4.5. You agree to receive electronic communications from the Service,
        including email newsletters. You can opt out of email newsletters at any
        time.
        <br />
        4.6. You agree not to provide or modify any information in your profile
        that is or becomes inaccurate or misleading.
        <br />
        4.7. You agree not to register more than one User Account without
        specific written permission from us.
        <br />
        4.8. You agree not to authorize any other person to create an account on
        your behalf, for your use, or for your benefit.
        <br />
        4.9. We reserve the right, at our sole discretion, to refuse, suspend, or
        cancel your access to the Service if it is discovered that any
        information provided by you in any form on the Service is inaccurate, or
        incomplete, or if there is information or actions violating this
        Agreement, or for any other reason or no reason at all, at our sole
        discretion.
      </p>
      <h2>5. Pricing and Payment Procedures</h2>
      <p>
        5.1. The cost of the tariff plans is located at{" "}
        <Link to="/tariffs">https://workbet.info/tariffs</Link>.
        <br />
        5.2. Payment for a tariff plan is made to our company’s account. Payment
        can be made by the User using payment cards from international payment
        systems Visa, MasterCard, in real time on the Service.
        <br />
        5.3. Payment is made for a period calculated in days, depending on the
        type of tariff plan. The Service can be paid for the minimum possible
        period of Service use. Payment is charged and paid by the User
        regardless of the User’s actual use of the Service.
        <br />
        5.4. The Service fee is deemed approved, and the User’s Account is
        credited after the bank’s confirmation of the transfer of funds to our
        company’s account.
      </p>
      <h2>6. Disclaimer of Warranties</h2>
      <p>
        6.1. You agree not to rely on the Service, any information on the
        Service, or any links provided on the Service. No information provided
        by the Service should be considered financial or investment advice on
        managing your funds.
        <br />
        6.2. The Service is provided "as is" and "as available". We make no
        express or implied warranties regarding the Service or any actions or
        matters related to this Agreement.
        <br />
        6.3. We make no warranty that your access to our Service or its content
        will be uninterrupted, timely, or error-free, or that the Service will
        be free of viruses or other harmful elements.
        <br />
        6.4. We make no warranty that the Service or the information on it will
        meet your requirements.
        <br />
        6.5. We make no warranty regarding any results you may obtain from using
        the Service. If you do obtain any results, we make no representation
        that such results will be effective, accurate, or reliable.
        <br />
        6.6. We make no warranty that the quality of any information you
        acquire or receive through the Service will meet your expectations or be
        free from errors or defects.
        <br />
        6.7. To the fullest extent permitted by applicable law, we disclaim all
        express and implied conditions, representations, and warranties,
        including but not limited to accuracy, fitness for a particular purpose,
        and non-infringement.
        <br />
        6.8. In some jurisdictions, not all of the above limitations on
        warranties are allowed; therefore, some or all of the listed limitations
        may not apply to you.
      </p>
      <h2>7. Links to Third-Party Websites</h2>
      <p>
        7.1. Our Service contains information about and links to other websites.
        <br />
        7.2. We do not control the accuracy or completeness of this information.
        You assume full responsibility for the use of this information and for
        verifying its accuracy, as well as any decision to use or purchase (or
        refrain from purchasing) any services or products mentioned on the
        linked websites.
        <br />
        7.3. If you purchase products or services from another website, carefully
        read that website’s terms before deciding to purchase. Remember that you
        accept the terms of the other website, not ours.
        <br />
        7.4. The Service provides these links and information for convenience,
        and the inclusion of a link or provided information does not imply
        advertising, endorsement, or any warranty of any other website by our
        Service.
      </p>
      <h2>8. Limitation of Liability</h2>
      <p>
        8.1. We make our Service available in good faith, but we cannot and do
        not guarantee the completeness, truthfulness, or accuracy of the
        information or any other content or messages contained on our Service,
        nor its usefulness for any particular purpose.
        <br />
        8.2. You acknowledge and agree that you bear full responsibility for your
        own actions and decisions in using the Service and that we are not
        responsible for any actions that you or others take or fail to take
        based on your use of or reliance on the information provided by the
        Service.
        <br />
        8.3. We are not liable, and you agree not to hold us liable, for any
        damages or losses arising in connection with this Agreement, including
        but not limited to:
      </p>
      <ul>
        <li>Your use of or inability to use our Service;</li>
        <li>Delays or failures in our Service;</li>
        <li>
          Viruses or other malicious software obtained by accessing or linking
          to our Service;
        </li>
        <li>Failures, errors, or inaccuracies in our Service;</li>
        <li>Damage to your hardware resulting from using our Service;</li>
        <li>
          The content, actions, or omissions of third parties in their use of
          the Service;
        </li>
        <li>
          Suspension or other action taken regarding your Account;
        </li>
        <li>
          Your reliance on the quality, accuracy, or reliability of the
          information or indicators found, used, or made available through the
          Service.
        </li>
      </ul>
      <p>
        8.4. Furthermore, under no circumstances will our company be liable for
        any special, indirect, incidental, punitive, direct, or consequential
        damages or losses, including but not limited to legal fees, costs of
        installation and removal, loss of data, loss of profit, or business
        opportunities.
        <br />
        8.5. These limitations apply to all liabilities arising out of any
        action under this Agreement or in connection with it, whether in tort
        (including negligence), administrative liability, or otherwise, even if
        we were advised of the possibility of such costs or damages, and even if
        the limited remedies provided herein fail in their essential purpose.
        <br />
        8.6. In some states and jurisdictions, not all of the above exclusions
        and limitations are allowed; hence, some or all of these limitations and
        exclusions may not apply to you.
      </p>
      <h2>9. Dispute Resolution</h2>
      <p>
        9.1. Any disputes between the Parties regarding the fulfillment of this
        Agreement shall be resolved through negotiations between the Parties.
        The Parties establish a mandatory pre-trial dispute resolution procedure
        by submitting a claim in accordance with international law procedures.
        <br />
        9.2. We will accept claims provided by the User in writing within a
        period not exceeding three (3) calendar days from the date the dispute
        arises. The time for reviewing the User’s claims is no more than
        fourteen (14) calendar days.
        <br />
        9.3. Claims related to the provision of the Service will be considered
        only if the User provides the relevant financial documents confirming
        payment for the Service.
        <br />
        9.4. In disputes, the Parties may provide printed electronic emails as
        evidence, with the mandatory inclusion of an email subject line. If the
        subject is missing, the email is not considered valid evidence.
        <br />
        9.5. This Agreement is drawn up in English and Russian. In case of
        dispute, the English version shall prevail.
      </p>
      <h2>10. Indemnification</h2>
      <p>
        10.1. You agree to indemnify and hold us and (if applicable) our founders,
        directors, agents, and employees harmless from any claims or demands,
        including legal fees, by any third party due to or arising out of your
        breach of this Agreement or your violation of any law or the rights of a
        third party.
      </p>
      <h2>11. Intellectual Property</h2>
      <p>
        11.1. Under the terms of this Agreement, once you have created an
        Account, the Service grants you a limited license to access and use the
        Service.
        <br />
        11.2. You must not access (or attempt to access) the Service by any means
        other than the interface provided, and you agree not to use the
        information obtained on the Service for any purposes other than those
        for which it was made available.
        <br />
        11.3. You agree not to use multiple devices/browsers simultaneously to
        access the Service.
        <br />
        11.4. You agree not to use the Service to provide any goods or services.
        <br />
        11.5. You may not sell, reproduce, distribute, modify, display publicly,
        perform publicly, create derivative works based on any content found on
        the Service, repost, or otherwise use any content from the Service in
        any way for any public or commercial purpose without our prior written
        consent.
        <br />
        11.6. You must not use any content from the Service on any other website
        or in any networked computer environment for any purpose other than your
        own viewing without our prior written consent.
        <br />
        11.7. You may not share your account details with third parties to use
        the Service jointly from one account.
        <br />
        11.8. You must not attempt to decrypt, modify, adapt, translate, prepare
        derivative works, decompile, interfere with, or otherwise attempt to
        obtain source code from any part of the Service.
        <br />
        11.9. You must not use access to the Service to create a similar service
        or application, or publish any presentation or any review, test, or
        analysis related to the Service.
        <br />
        11.10. We retain all rights, including copyrights and legal interests, in
        relation to intellectual property objects located on the Service.
        <br />
        11.11. The Service’s logos and names are our company’s trademarks and
        may be registered in certain jurisdictions.
        <br />
        11.12. Except as expressly provided in this Agreement, nothing herein
        shall grant any exclusive license to any of our company’s rights or any
        third-party intellectual property rights, whether by estoppel,
        implication, or otherwise.
      </p>
      <h2>12. Mobile Devices</h2>
      <p>
        12.1. When using our mobile application, please note that your carrier’s
        standard rates and fees may still apply for data transmission. The
        mobile application may not contain certain functionality available on
        the website.
      </p>
      <h2>13. Final Provisions</h2>
      <p>
        13.1. If any of these provisions is found to be invalid or unenforceable,
        that provision will be deemed severable from the rest and will not
        affect the validity or enforceability of any remaining provisions.
        <br />
        13.2. These terms are governed by the laws of the Russian Federation, and
        any dispute between the parties is subject to the exclusive jurisdiction
        of the courts of the Russian Federation.
        <br />
        13.3. We do not sell, rent, or otherwise transfer your personal
        information to third parties, except as described in the Privacy Policy,
        for instance, when we believe we are obliged to do so by law.
        <br />
        13.4. Headings are provided for reference purposes only and do not in any
        way define, limit, interpret, or describe the scope or extent of any
        section.
        <br />
        13.5. Any inaction on our part regarding your or anyone else’s violation
        of this Agreement does not waive our right to act with respect to
        subsequent or similar violations of this Agreement.
        <br />
        13.6. By accepting these rules, the User agrees that they have read and
        understood all the provisions listed in this Agreement.
      </p>
      <h2>14. Contact Us</h2>
      <p>
        14.1. If you wish to report a violation of this Agreement, have any
        questions, or need assistance, please contact User Support at:
      </p>
      <p>
        <b>Email:</b> info@workbet.info
      </p>
    </>
  )
}