export const ForksEn = () => {
  return (
    <>
      <h1 className="main__title">Surebets</h1>
      <h2>How It Works</h2>
      <p>
        Our service allows you to place bets without risk: you earn a return
        regardless of the outcome of the sporting event! How is this possible?
        By using bookmaker surebets*, which are calculated through simple
        mathematical formulas that make profiting from surebets a reality.
      </p>
      <p>
        * Bookmaker surebets are mathematically calculated arbitrage situations
        that arise from differences in odds offered by different bookmakers.
        They allow you to bet on all opposing outcomes of a match at different
        bookmakers in such a way that you earn a profit no matter how the match
        ends!
      </p>
      <h2>Why Do Bookmaker Surebets Occur?</h2>
      <ol>
        <li>
          High competition among bookmakers, often leading to inflated odds.
        </li>
        <li>
          Bookmakers cannot always keep track of changing odds at rival
          bookmakers, creating discrepancies between their own odds.
        </li>
        <li>
          Mistakes by bookmakers themselves, resulting in incorrect odds
          calculations.
        </li>
      </ol>
      <p>
        We scan bookmaker odds, calculate surebets using mathematical formulas,
        and tell you WHERE and HOW MUCH you need to bet so that you earn a
        guaranteed profit regardless of the match result.
      </p>
      <p>
        You can earn from 5% to as much as 15–20% profit on your total investment.
        And because your bankroll will keep growing from your winnings, your
        capital will increase exponentially.
      </p>
      <h2>Types of Bookmaker Surebets</h2>
      <p>
        Surebets can be categorized by the number of outcomes (two-outcome bets)
        and by the match start time (prematch or live).
      </p>
      <p>
        Prematch surebets arise from differences in odds before the event starts,
        while live surebets appear in events already in progress. Both prematch
        and live betting have their advantages and disadvantages.
      </p>
      <p>
        Among the main advantages of prematch surebets are the slower changes in
        odds and simpler execution, whereas they tend to yield lower profitability
        and slower bankroll turnover.
      </p>
      <p>
        Live surebets, on the other hand, typically provide higher profitability
        and lower risk, but they are not well suited for beginners due to the
        rapid changes in odds — meaning these surebets have a very short “lifetime.”
      </p>
      <h2>Example of a Bookmaker Surebet</h2>
      <p>
        Let’s consider a basketball match between two teams: Leicester Warriors
        and Westminster Warriors. We have a large selection of bookmakers, each
        offering different odds on Team 1 or Team 2. Using our specially designed
        scanning and surebet calculation technology, our service does the
        following:
      </p>
      <ol>
        <li>
          Scans all available odds for the Leicester Warriors vs. Westminster
          Warriors match across different bookmakers.
        </li>
        <li>
          Identifies the best odds among all possibilities and uses simple math
          to calculate any existing surebets.
        </li>
        <li>
          Selects the most profitable surebets and tells you where and how much
          money to bet in order to secure a guaranteed profit.
        </li>
      </ol>
      <p>
        Suppose, after scanning the bookmakers for the Leicester Warriors vs.
        Westminster Warriors match, the service highlights two bookmakers with
        the following parameters:
      </p>
      <table className="public-table">
        <tbody>
          <tr>
            <td>Leicester Warriors</td>
            <td>Bookmaker</td>
            <td>Westminster Warriors</td>
          </tr>
          <tr>
            <td>1.25</td>
            <td>B1</td>
            <td>3.9</td>
          </tr>
          <tr>
            <td>1.43</td>
            <td>B2</td>
            <td>2.85</td>
          </tr>
        </tbody>
      </table>
      <p>We determine the sum of the inversions (L) of all outcomes at each bookmaker:</p>
      <b>
        B1: L = 1/1.25 + 1/3.9 = 1.056
        <br />
        B2: L = 1/1.43 + 1/2.85 = 1.051
      </b>
      <p>
        Accordingly, no matter how the match ends, Bookmaker 1 (B1) is on track
        to gain 5.6% profit, and Bookmaker 2 (B2) 5.1% profit. Our objective is
        to identify such odds across different bookmakers so that the sum of the
        inversions of opposing outcomes is less than 1. Only then do we have a
        viable surebet:
      </p>
      <p>
        1. We calculate the sum of the inversions (L) for the opposing bets at B1
        and B2. Imagine we bet on Westminster Warriors (win) at Bookmaker 1 (B1)
        and on Leicester Warriors (win) at Bookmaker 2 (B2). In that scenario:
      </p>
      <b>L = 1/3.9 + 1/1.43 = 0.9557</b>
      <p>
        As you can see, the sum of the inversions for these opposing bets is
        below 1. Therefore, these two bookmakers with those odds are suitable
        for further calculations.
      </p>
      <p>
        2. Next, we calculate how much money to bet and where — assuming we have
        $1,000 available for surebetting. The bets (V1) at B1 and (V2) at B2 are:
      </p>
      <b>
        V1 = 1000 / (0.9557 * 3.9) = $268.30
        <br />
        V2 = 1000 / (0.9557 * 1.43) = $731.70
      </b>
      <p>
        Hence, to guarantee a return from this surebet, we must bet $268.30 on
        Westminster Warriors at B1 and $731.70 on Leicester Warriors at B2.
      </p>
      <p>
        3. Finally, we calculate the guaranteed profit (P) from the total sum
        wagered across both bookmakers:
      </p>
      <p>
        If Westminster Warriors win:{" "}
        <b>P = 268.30 x 3.9 - (268.30 + 731.70) = $46.33</b>
        <br />
        If Leicester Warriors win:{" "}
        <b>P = 731.70 x 1.43 - (268.30 + 731.70) = $46.33</b>
      </p>
      <div className="color-pink">
        <p>
          From these calculations, you can see that with a total stake of $1,000,
          you are guaranteed to receive $1,046.33, giving a net profit of $46.33
          (4.42% of the total amount staked).
        </p>
        <p>
          Congratulations! You’ve just made your first profit using a bookmaker
          surebet. Now imagine repeating this process multiple times a day with
          larger amounts. That’s how you can earn excellent returns!
        </p>
      </div>
    </>
  )
}