import { Link } from "react-router-dom"

export const PrivacyEn = () => {
  return (
    <>
      <h1 className="main__title">Privacy Policy</h1>
      <p>
        This document explains how the service uses private information collected
        during its operation. This policy does not apply to third parties not
        controlled by the service.
      </p>
      <p>
        Contents
        <ul>
          <li>Collection and Use of Information</li>
          <li>Access to Information</li>
          <li>Cookies</li>
          <li>Data Storage</li>
          <li>Security</li>
          <li>Changes</li>
          <li>Collection and Use of Information</li>
        </ul>
      </p>
      <p>
        We receive and store certain types of information whenever you use the
        service. Some data about traffic, obtained from your browser (including
        your IP address, cookie information, and the page you requested), is
        automatically saved in server logs. We use this information solely to
        diagnose server problems, analyze statistics, and administer the website.
      </p>
      <p>
        We collect the addresses from which requests are made, aggregate
        information about viewed pages, as well as information provided by the
        user. The data we collect is used to improve our service quality and is
        not shared or sold to other organizations for commercial purposes.
      </p>
      <p>
        <b>Access to Information</b>
        <br />
        Access to information is carried out only according to the following
        rules:
        <ul>
          <li>
            The service administration may have access to user information when
            performing its official duties.
          </li>
          <li>
            We may disclose personal information if necessary to cooperate with
            law enforcement; to enforce user and other agreements; or to protect
            the rights, property, or safety of the service, our employees, and
            users. This includes sharing information with other companies and
            organizations for fraud protection.
          </li>
        </ul>
      </p>
      <p>
        Except as noted above, you will be notified when your personal
        information may be disclosed to third parties or used for purposes other
        than those for which it was collected, and you will have the opportunity
        to prevent such sharing of information.
      </p>
      <p>
        <b>Cookies</b>
        <br />
        Cookies are small pieces of information that often include an anonymous
        identifier sent to your browser by a web page, stored on your computer’s
        hard drive. Cookies are necessary to use the service.
      </p>
      <p>
        <b>Data Storage</b>
        <br />
        Third parties are involved in providing the necessary software,
        technical, network, and other equipment to maintain the service for data
        storage.
      </p>
      <p>
        <b>Security</b>
        <br />
        Your account information is protected by a password. To prevent
        unauthorized access to your account, you should log out after finishing
        your session.
      </p>
      <p>
        <b>Changes</b>
        <br />
        The administration reserves the right to modify this policy.
      </p>
      <p>
        This Privacy Policy applies to all data and information that the
        Operator of the Workbet.info website may obtain about you as a person
        using the site{" "}
        <Link to="/">https://workbet.info/</Link>. This Privacy Policy governs
        the procedure for processing (i.e., collecting, storing, using,
        systematizing, etc.) the information obtained about you during your use
        of <Link to="/">https://workbet.info/</Link>. Any use of{" "}
        <Link to="/">https://workbet.info/</Link> (for example: browsing,
        registering on the site, using the site’s services, accepting offers,
        receiving services provided on the site) implies your unconditional
        acceptance of this document and the terms specified herein, so the
        Company asks you to read the Privacy Policy carefully. If you disagree
        with these terms, you must refrain from using{" "}
        <Link to="/">https://workbet.info/</Link> and cease the registration
        process on this site. If any provisions of the Privacy Policy are not
        clear or understandable to you, you should contact our support service
        by sending an email to: info@workbet.info.
      </p>
      <p>
        For the purposes of this document, the following concepts and definitions
        are used:
        <br />
        Privacy Policy (Policy) – a document that regulates the procedure for
        processing all data and information about the User by the website;
        <br />
        Processing of User information – any actions performed by the Site
        Operator with the User’s data and information. For example: collection,
        recording, systematization, accumulation, storage, refinement
        (updating, changing), extraction, use, transfer (providing access to
        information/data), depersonalization, blocking, deletion, destruction of
        data. Processing can be carried out both using automation tools and
        without them;
        <br />
        Website – an internet resource located at{" "}
        <Link to="/">https://workbet.info/</Link>;
        <br />
        Website User (User) – a person who uses the Website in any manner not
        prohibited by law. Throughout this Policy, "you" or "your" refers to the
        User;
        <br />
        Site Operator (Company) – <Link to="/">https://workbet.info/</Link>.
      </p>
      <h2>I. User Information Processed by the Company</h2>
      <p>
        1.1. Within this Policy, "User information" means:
        <br />
        Information that the User provides about themselves when registering
        (creating an account) or during the use of the Website;
        <br />
        Data automatically transmitted by the Company’s services in the course
        of their use through software installed on the User’s device, including
        IP address, cookie data, data on the use of services and visits to other
        websites using an account on the Company’s site, data on viewing
        advertisements on the Website, information about the User’s browser (or
        other software used to access the Services), technical characteristics of
        the hardware and software used by the User, date and time of access to
        the Services, and other similar information;
        <br />
        Data and information transmitted by the User when contacting the
        Company’s support service.
        <br />
        1.2. This Policy applies only to information processed during the use of
        the Website. The Company does not control and is not responsible for the
        processing of information by third-party websites that the User may
        access via links available on the Website.
        <br />
        1.3. The Company is unable to verify the accuracy of the information
        provided by the User, nor can it assess their legal capacity. However,
        the Company assumes that the User provides truthful and sufficient
        information and maintains this information in an up-to-date state. If it
        is discovered that the User provided inaccurate information, the Company
        has the right to block the User’s account and restrict their access to
        the Website and its services.
      </p>
      <h2>II. Rules for Processing User Information by the Company</h2>
      <p>
        2.1. The Company collects and stores only the information necessary for
        the User’s work on the Website or to fulfill agreements and contracts
        with the User.
        <br />
        2.2. The Company processes the User’s information for the following
        purposes:
        <br />
        Identifying the User when they use the Website, for concluding
        agreements and contracts with the Company;
        <br />
        Providing the User with a personalized personal account, fulfilling
        agreements and contracts;
        <br />
        Communicating with the User, including sending notifications, requests,
        and information regarding the use of the Website, fulfilling agreements
        and contracts, as well as processing inquiries and requests from the
        User;
        <br />
        Improving the quality of services provided on the Website, enhancing the
        convenience of using the Website, and developing new products and
        services;
        <br />
        Conducting statistical and other research.
        <br />
        2.3. The User agrees that the information they provide will be used by
        the Company for personalizing content on the Website.
      </p>
      <h2>
        III. Conditions for Using Information Obtained from the User.
      </h2>
      <p>
        Consent to the processing of personal data and their transfer to third
        parties
        <br />
        3.1. The User agrees that some of the information they provide (nickname,
        email address) may be accessible to other users of the Website and the
        Company’s services. If it is necessary to hide any data from other
        users, the User must send a corresponding request to info@workbet.info
        or use the relevant function on the Website.
        <br />
        3.2. The User’s data may be transferred to the Project’s Partners for
        the fulfillment of agreements concluded by the User on the Website. If
        such a transfer is necessary, additional consent will be requested from
        the User on the Website.
        <br />
        3.3. The Company is entitled to transfer User information to third
        parties under the following circumstances:
        <br />
        The User has given consent for such actions; or The transfer is
        necessary for the use of the Website and the Company’s Services or for
        fulfilling a specific agreement or contract with the User; or at the
        request of government authorities, judicial authorities, and officials,
        or in other cases provided for by the legislation of the country under
        whose jurisdiction the User resides.
        <br />
        3.4. If necessary, upon request from the Company, the User is obliged to
        provide their personal data to the Company. Personal data is any
        information directly or indirectly related to the User, including but
        not limited to: last name, first name, patronymic, date and place of
        birth, passport data, status. If the User fails to provide their personal
        data upon request, the User’s account may be blocked.
        <br />
        3.5. In the event the User provides their personal data to the Company,
        the User consents to its processing and storage. In this Policy,
        “processing of personal data” means any actions (operations) carried out
        using automation tools or without such tools concerning personal data,
        including collection, recording, systematization, accumulation, storage,
        updating (modification), extraction, use, transfer (distribution,
        provision, access), depersonalization, blocking, deletion, and
        destruction of personal data.
        <br />
        3.6. The User is responsible for any information they publish on the
        Website and agrees that such information will be accessible to other
        Users. The User agrees that any information posted by them on the
        Website can be read, collected, or used by the Company, other Users, or
        third parties (if it is in open access).
      </p>
      <h2>
        IV. Changing and Deleting Information. Withdrawal of Consent to Data
        Processing
      </h2>
      <p>
        4.1. The User may change (update or supplement) the information they have
        provided, or part of it, at any time by sending an email to:
        info@workbet.info.
        <br />
        4.2. The User may delete the personal information they provided under the
        relevant account at any time by sending an email to info@forking.bet.
        <br />
        4.3. The rights provided in paragraphs 4.1 and 4.2 of this Policy may be
        restricted in accordance with legal requirements. In particular, such
        restrictions may require the Company to retain information modified or
        deleted by the User for a period specified by law and to transfer such
        information according to a legally established procedure to a
        governmental authority.
        <br />
        4.4. The User may withdraw their consent to the processing of their data
        at any time by sending an email to info@workbet.info.
      </p>
      <h2>V. Use of Cookies and Counters</h2>
      <p>
        5.1. The Company uses cookies to personalize the capabilities of the
        Website and the Company’s services, to provide the User with
        personalized services, to target advertising, for statistical and
        research purposes, and to improve the performance of services and the
        Website.
        <br />
        5.2. The User understands that the hardware and software they use to
        browse websites may have the function of prohibiting cookie operations
        (for all sites or for certain sites) and may also delete previously
        received cookies.
        <br />
        5.3. The Company sets forth that use of the Website and the Company’s
        services is only possible if cookies are accepted by the User. By using
        the Website and the Company’s services, the User agrees to allow cookies
        to be placed in their browser.
        <br />
        5.4. The structure, content, and technical parameters of a cookie are
        determined by the Company and may be changed without prior notice to the
        User.
        <br />
        5.5. Counters placed by the Company on the Website and in the Services
        may be used to analyze the User’s cookies, to collect and process
        statistical information about the use of the Services, and also to
        ensure the operability of the Services in general or of particular
        features. The technical parameters of the counters are determined by the
        Company and may be changed without prior notice to the User.
      </p>
      <h2>
        VI. Responsibility for Storing Data Required for Using the Company’s
        Website and Services
      </h2>
      <p>
        6.1. The User must ensure the security of all data (login, password, ID,
        etc.) required to use the Website and the Company’s services.
        <br />
        6.2. If the User becomes aware of or suspects unauthorized access to
        their data by third parties, the User must immediately change the
        password used to log into the Company’s Website and also inform the
        Company about these facts.
        <br />
        6.3. The User assumes exclusive responsibility for any partial or full
        use of the Website and the Company’s services under their unique login
        or ID number and password.
      </p>
      <h2>VII. Measures Applied to Protect User Information</h2>
      <p>
        7.1. The Company takes the necessary and sufficient organizational and
        technical measures to protect the User’s information from unauthorized
        or accidental access, destruction, alteration, blocking, copying,
        distribution, as well as other illegal actions by third parties.
      </p>
      <h2>VIII. Duration of Information Storage</h2>
      <p>
        8.1. The information provided by the User is stored for 6 months from the
        time it is provided, except in the cases specified in clause 8.2 of this
        Policy.
        <br />
        8.2. If the User accepts offers (offerts) or concludes agreements with
        the Company, the information provided by the User is stored for the
        entire duration of the offers/agreements and for three (3) years after
        the offers/agreements terminate.
        <br />
        8.3. If it is necessary to reduce the storage period of the information
        provided by the User, the User may send an email describing the
        situation to: info@workbet.info.
      </p>
      <h2>IX. Changing the Privacy Policy</h2>
      <p>
        9.1. The Company has the right to make changes to this Privacy Policy. In
        the latest version, the date of the last update is indicated. The new
        version of the Policy takes effect from the moment it is posted, unless
        otherwise stipulated by the new version of the Policy. The current
        version is always available on the Website.
        <br />
        9.2. If you disagree with the changes to the Privacy Policy, you must
        delete your account. Continuing to use the Website and the Company’s
        services signifies acceptance of the changes made to this Policy.
      </p>
      <p>
        This policy becomes effective on 01.11.2022.
        <br />
        At <Link to="/">https://workbet.info/</Link>
      </p>
    </>
  )
}