import { Link } from "react-router-dom"

export const TermsRu = () => {
  return (
    <>
      <h1 className="main__title">Пользовательское соглашение</h1>
      <p>Дата вступления в силу: 01 Ноября, 2022</p>
      <p>
        Настоящее Пользовательское соглашение (Далее Соглашение) является
        договором между Вами («Вы» или «Пользователь») и владельцами сайта
        workbet.info («Мы» или «Нами»). Вы должны прочитать, согласиться и
        принять все условия, содержащиеся в настоящем Соглашении, для
        использования веб-сайта, расположенного по адресу{" "}
        <Link to="/">workbet.info</Link>, связанными мобильным веб-сайтом и
        мобильным приложением, принадлежащих и управляемых нами (Далее вместе
        именуемые «Сервис»).
      </p>
      <p>
        Настоящее соглашение включает и тем самым закрепляет следующее: термины,
        используемые в соглашении, предмет соглашения, права и обязанности
        сторон, регистрация аккаунта; профиль, стоимость и порядок расчетов,
        отказ от гарантий, ссылки на сайты третьих сторон, исключение
        ответственности, разрешение споров, контрибуции, интеллектуальная
        собственность, мобильные устройства, заключительные положения.
      </p>
      <p>
        В соответствии с условиями, изложенными в настоящем документе, мы можем
        по собственному усмотрению изменить настоящее Соглашение в любое время,
        разместив в Сервисе пересмотренную версию. Если существенное изменение
        включает в себя увеличение стоимости, взимаемых нами тарифов, мы
        предоставить уведомление об изменениях за 30 дней, любые другие
        изменения осуществляются без предварительного уведомления. Любые
        изменения настоящего Соглашения вступают в силу с момента опубликования,
        если не будет указана дата вступления в силу или с указанной Даты
        вступления в силу.
      </p>
      <p>
        Ваше дальнейшее использование Сервиса после вступления в силу новой
        редакции настоящего Соглашения означает Ваше согласие с новой редакцией.
      </p>
      <p>
        ВЫ ПОНИМАЕТЕ, ЧТО, ПОЛЬЗУЯСЬ СЕРВИСОМ ПОСЛЕ ДАТЫ ВСТУПЛЕНИЯ В СИЛУ
        НАСТОЯЩЕГО СОГЛАШЕНИЯ, ВЫ ПОЛНОСТЬЮ СОГЛАШАЕТЕСЬ С ЕГО УСЛОВИЯМИ. ЕСЛИ
        ПОСЛЕ ВСТУПЛЕНИЯ В СИЛУ НАСТОЯЩЕГО СОГЛАШЕНИЯ ВЫ НЕ ПРИНИМАЕТЕ ЕГО
        УСЛОВИЯ В ПОЛНОМ ОБЪЕМЕ, ВЫ НЕ ДОЛЖНЫ ПОЛЬЗОВАТЬСЯ СЕРВИСОМ
      </p>
      <h2>1. Термины, используемые в соглашении</h2>
      <p>
        1.1. Следующие слова и выражения и аббревиатуры, если только контекст не
        требует иного, имеют следующее значение:
      </p>
      <p>
        <b>Соглашение</b> – это пользовательское соглашение (публичная оферта)
        между Пользователем и нами, актуальная версия которого располагается по
        адресу{" "}
        <Link to="/profile/account">https://workbet.info/profile/account</Link>
        <br />
        <b>Аккаунт</b> – учетная запись, которую Вы создаете при регистрации на
        Сервисе.
        <br />
        <b>Сервис</b> – все услуги доступные на сайте, на связанном с ним
        мобильном сайте и в мобильном приложении, которые предоставляются по
        адресу <Link to="/">https://workbet.info</Link>, принадлежат и
        контролируются нами.
        <br />
        <b>Пользователь</b> – пользователь создающий на сайте Аккаунт и
        уполномочивается использовать Сервис.
      </p>
      <h2>2. Предмет соглашения</h2>
      <p>
        2.1. Настоящее Соглашение является официальным предложением (публичной
        офертой) и содержит все существенные условия предоставления Сервиса.
        <br />
        2.2. Регистрируя аккаунт в Сервисе или нажав кнопку принять Соглашение
        при запросе на Сервис, Вы принимаете настоящее Соглашение действительным
        на дату регистрации вашего Аккаунта или на дату нажатия кнопки принять
        Соглашение. Регистрация Вашего аккаунта является подтверждением того,
        что Вы можете получать, загружать и распечатывать данное Соглашение в
        электронном виде. Вы несете ответственность за точность информации,
        предоставленной при регистрации.
        <br />
        2.3. Сервис выполняет сканирование линий букмекерских контор в
        автоматическом режиме и предоставляет информацию об арбитражных
        ситуациях (букмекерских вилках), возникающих в результате анализа линий.
        Информация, опубликованная в Сервисе, предоставляется Пользователю по
        подписке в соответствии с выбранным тарифным планом.
        <br />
        2.4. Сервис не является финансовым инструментом, он не оказывает услуги
        по управлению средствами, привлеченными от третьих лиц, и не является
        букмекерской конторой или организатором азартных игр.
      </p>
      <h2>3. Права и обязанности сторон</h2>
      <p>
        3.1. Мы обязуется:
        <br />
        3.1.1. Предоставлять Услуги в соответствии с выбранным тарифным планом.
        <br />
        3.1.2. Публиковать отчеты, связанные с Сервисом, и изменение стоимости
        платежей, в Сервисе, а также отправлять их по электронной почте, если
        это необходимо.
      </p>
      <p>
        3.2. Пользователь обязуется:
        <br />
        3.2.1. Пользователь обязуется самостоятельно проверять компетентность
        информации, предоставляемой Сервисом, непосредственно перед любым
        возможным использованием такой информации.
        <br />
        3.2.2. Пользователь принимает на себя все риски возможных потерь или
        возможной прибыли, связанных с использованием информации,
        предоставляемой Сервисом.
        <br />
        3.2.3. Пользователь обязуется не распространять любую информацию,
        полученную с использованием Сервиса, без согласия Администрации.
        <br />
        3.2.4. Пользователь обязуется не использовать никаких автоматических
        инструментов (роботов) для многократного считывания информации,
        предоставляемой Сервисом.
        <br />
        3.2.5. Пользователь вносит плату Нам за право пользования Сервисом в
        соответствии с положениями раздела 5.
      </p>
      <p>
        3.3. Мы вправе:
        <br />
        3.3.1. Мы имеет право ограничить доступ Пользователя к Сервису в случае,
        если Пользователь не соблюдает настоящее Соглашение. Если есть основания
        подозревать Пользователя в нарушении каких-либо пунктов настоящего
        Соглашения, доступ Пользователя к Сервису может быть заблокирован до
        выяснения обстоятельств.
        <br />
        3.3.2. Мы имеет право добавлять или исключать те или иные данные и
        услуги по своему усмотрению.
        <br />
        3.3.3. Мы имеет право изменять стоимость тарифных планов,
        предоставляемых Пользователю. Новые цены на тарифные планы вступают в
        силу с даты их публикации на Сервисе. Изменения в стоимости тарифных
        планов не применяются к уже оплаченным тарифным планам.
      </p>
      <p>
        3.4. Пользователь вправе:
        <br />
        3.4.1. Использовать Сервис только для некоммерческого личного
        использования, независимо от выбранного тарифного плана.
        <br />
        3.4.2. Получать информацию от нас о Сервисе, стоимости и порядке
        использования Сервиса.
        <br />
        3.4.3. Обратится к нам с жалобами и предложениями по улучшению качества
        Сервиса.
      </p>
      <h2>4. Регистрация аккаунта, профиль</h2>
      <p>
        4.1. Для пользования Сервисом, необходимо зарегистрировать Аккаунт. При
        регистрации Аккаунта, Вы должны заполнить профиль Пользователя.
        <br />
        4.2. Регистрируя Аккаунт Вы тем самым подтверждаете, что Вы являетесь
        физическим лицом, достигшим возраста 18 лет, дееспособным для заключения
        настоящего юридически обязывающего Соглашения.
        <br />
        4.3. Вы подтверждаете, что не являетесь гражданином или резидентом
        государства, в котором доступ к Сервису или его использование запрещены
        действующим законодательством, декретом, регламентом, договором или
        административным актом.
        <br />
        4.3.1. Вы соглашаетесь с тем, что, если вышеуказанные обстоятельства в
        государстве вашего проживания или резидентства изменились так, что
        указанные выше условия более не относятся к Вам, Вы немедленно
        прекратите пользоваться Сервисом.
        <br />
        4.3.2. Вы подтверждаете, что не являетесь гражданином США и не
        находитесь на территории США.
        <br />
        4.4. Вы соглашаетесь предоставлять достоверную, точную и полную
        информацию в Вашем профиле и во всех регистрационных формах, к которым
        Вы обращаетесь на Сервисе, или связываться с нами для обновления Вашей
        информации, чтобы сохранять ее достоверность, точность и полноту.
        <br />
        4.5. Вы соглашаетесь на получение электронных сообщений от Сервиса, в
        том числе и в виде электронной рассылки. От электронной рассылки можно
        отказаться в любое время.
        <br />
        4.6. Вы соглашаетесь не предоставлять и не изменять любую информацию о
        Вашем профиле, которая является или становится недостоверной, или
        вводящей в заблуждение.
        <br />
        4.7. Вы соглашаетесь не регистрировать более чем один Аккаунт
        Пользователя без специального письменного разрешения от нас.
        <br />
        4.8. Вы соглашаетесь не уполномочивать другое лицо создавать учетную
        запись от Вашего имени, для Вашего использования или для Вашей выгоды.
        <br />
        4.9. Мы оставляет за собой право, по собственному усмотрению, отказать,
        приостановить или аннулировать Ваш доступ к Сервису после обнаружения
        того, что любая информация, предоставленная Вами в любой форме на
        Сервисе, не соответствует действительности, не является точной или
        полной, или при наличии информации или действий, нарушающих настоящее
        Соглашение, или по любой другой причине или без повода по собственному
        усмотрению нами.
      </p>
      <h2>5. Стоимость и порядок расчетов</h2>
      <p>
        5.1. Стоимость тарифных планов содержится по адресу{" "}
        <Link to="/tariffs">https://workbet.info/tariffs</Link>
        <br />
        5.2. Оплата тарифного плана производится на счет нашей компании. Оплата
        может быть произведена Пользователем с использованием платежных карт
        международных платежных систем Visa, MasterCard в режиме реального
        времени на Сервисе.
        <br />
        5.3. Оплата производится за период, который рассчитывается в днях, в
        зависимости от типа тарифного плана. Сервис может быть оплачен, за
        минимально возможный период предоставления пользования Сервисом. Оплата
        взимается и оплачивается Пользователем независимо от использования
        Сервиса.
        <br />
        5.4. Плата за Сервис считается утвержденной, а Аккаунт Пользователя
        пополняется после получения от банка информации о переводе средств на
        счет нашей компании.
      </p>
      <h2>6. Отказ от гарантий</h2>
      <p>
        6.1. Вы соглашаетесь не полагаться на Сервис, любую информацию на
        Сервисе или ссылки, расположенные на Сервисе. Никакая информация,
        предоставленная Сервисом, не может рассматриваться как инструкция по
        распоряжению Вашими средствами.
        <br />
        6.2. Сервис предоставляется «как есть» и «по мере доступности». Мы не
        делаем никаких прямых заявлений или гарантий в отношении Сервиса или
        каких-либо действий, или вопросов, связанных с настоящим Соглашением.
        <br />
        6.3. Мы не даем никаких гарантий, что ваш доступ к нашему Сервису или
        его контенту будет доставлен бесперебойно, своевременно или без ошибок,
        или что Сервис не будет иметь вирусов или других вредных свойств.
        <br />
        6.4. Мы не даем никаких гарантий, что Сервис или информация на нем будут
        соответствовать Вашим требованиям.
        <br />
        6.5. Мы не даем никаких гарантий, что Вы получите какие-либо результаты,
        которые могут быть получены от использования Сервиса, или если у Вас
        будут какие-либо результаты, то они будут эффективными, точными или
        надежными.
        <br />
        6.6. Мы не даем никаких гарантий, что качество любой информации,
        приобретенной или полученной Вами с использованием Сервиса, будет
        соответствовать Вашим ожиданиям или будет свободно от ошибок или
        дефектов.
        <br />
        6.7. В максимальной степени, разрешенной действующим законодательством,
        мы отказывается от всех явных и подразумеваемых условий, представлений и
        гарантий, включая, но не ограничиваясь точностью, пригодностью для
        конкретной цели, и не нарушения прав.
        <br />
        6.8. В некоторых юрисдикциях могут не учитываться все Вышеперечисленные
        ограничения в отношении гарантий, по этой причине некоторые или все
        перечисленные Выше ограничения могут не применяться к Вам.
      </p>
      <h2>7. Ссылки на сайты третьих сторон</h2>
      <p>
        7.1. Наш Сервис содержит информацию о других сайтах и ссылки на них.
        <br />
        7.2. Мы не контролируем точность или полноту этой информации. Вы берете
        на себя полную ответственность за использование этой информации и
        проверку ее и за любое решение использовать, приобретать или
        воздерживаться от приобретения каких-либо услуг или продуктов,
        упомянутых на сайтах, ссылки которых расположены на нашем Сервисе.
        <br />
        7.3. Если Вы приобретаете продукты или услуги с другого веб-сайта,
        внимательно прочитайте условия данного сайта, прежде чем принимать
        решение о покупке. Помните, что Вы принимаете условия приобретения
        продуктов или услуг с другим сайтом, а не с нами.
        <br />
        7.4. Сервис предоставляет эти ссылки и информацию в целях удобства, и
        включение ссылки или предоставленной информации не подразумевает
        рекламу, одобрение или любую гарантию любого другого сайта нашим
        Сервисом.
      </p>
      <h2>8. Исключение ответственности</h2>
      <p>
        8.1. Мы добросовестно предоставляем в пользование наш Сервис, но мы не
        можем и не гарантируем полноту, правдивость или точность информации, или
        другого контента или сообщений, содержащихся на нашем Сервисе, или их
        полезности для какой-либо конкретной цели.
        <br />
        8.2. Вы признаете и соглашаетесь с тем, что Вы несете полную
        ответственность за свои собственные действия и решения по использованию
        Сервиса и что мы не несем ответственности за какие-либо действия,
        которые Вы или другие лица предпринимаете, или не предпринимаете на
        основании вашего использования или в зависимости от информации,
        предоставленной на Сервисе.
        <br />
        8.3. Мы не несет ответственности, и Вы соглашаетесь не привлекать нас к
        ответственности за любые убытки или потери, возникшие в связи с
        настоящим Соглашением, включая, но не ограничиваясь:
      </p>
      <ul>
        <li>
          ваше использование или невозможность использования нашего Сервиса;
        </li>
        <li>задержки или сбои в работе нашего Сервиса;</li>
        <li>
          вирусы или другое вредоносное программное обеспечение, полученное в
          результате доступа к нашему Сервису или ссылки на него;
        </li>
        <li>сбои, ошибки или неточности в нашем Сервисе;</li>
        <li>
          повреждение вашего аппаратного устройства от использования Сервиса;
        </li>
        <li>
          содержание, действия или бездействие третьих лиц в использовании
          Сервиса;
        </li>
        <li>
          приостановление или иное действие, предпринятое в отношении вашего
          Аккаунта;
        </li>
        <li>
          ваша уверенность в качестве, точности или надежности информации, или
          показателей, обнаруженных, используемых или доступных через Сервис.
        </li>
      </ul>
      <p>
        8.4. Кроме того, ни при каких обстоятельствах наша компания не будет
        нести ответственность за любые специальные, косвенные, случайные,
        штрафные, прямые или косвенные убытки, или ущерб, включая, но не
        ограничиваясь этим, судебные издержки, расходы на установку и удаление
        или потерю данных, утрату прибыли или деловых возможностей.
        <br />
        8.5. Эти ограничения будут применяться к любой ответственности,
        вытекающей из любых действий, на основании настоящего Соглашения или в
        связи с ним, по гражданским правонарушениям (включая небрежность),
        административной ответственности или иным образом, даже если было
        предупреждение о возможности таких расходов или убытков, и даже если
        ограниченные средства правовой защиты, предусмотренные в настоящем
        документе, не соответствуют основной цели.
        <br />
        8.6. В некоторых штатах и юрисдикциях не учитываются все вышеприведенные
        исключения и ограничения, поэтому некоторые или все из этих ограничений
        и исключений могут не относиться к Вам.
      </p>
      <h2>9. Разрешение споров</h2>
      <p>
        9.1. Любые споры между Сторонами относительно исполнения настоящего
        Соглашения разрешаются путем переговоров между Сторонами. Стороны
        устанавливают обязательную форму досудебного разрешения споров путем
        подачи требования в соответствии с процедурой международного права.
        <br />
        9.2. Мы будет принимать претензии, которые предоставляются Пользователем
        в письменной форме и в течение периода, не превышающего три (3)
        календарных дня с момента возникновения спора. Время рассмотрения
        претензий Пользователя составляет не более четырнадцати (14) календарных
        дней.
        <br />
        9.3. Рассмотрение претензий, связанных с предоставлением Сервиса,
        осуществляется, если Пользователь предоставляет соответствующие
        финансовые документы, подтверждающие оплату Сервиса.
        <br />
        9.4. В спорах стороны имеют право предоставлять печатные электронные
        письма в качестве доказательств, с обязательным названием темы письма.
        Если тема в письме отсутствует, письмо не считается доказательством.
        <br />
        9.5. Настоящее Соглашение составлено на английском и русском языках. В
        случае спора применяется английская версия.
      </p>
      <h2>10. Контрибуции</h2>
      <p>
        10.1. Вы соглашаетесь компенсировать и ограждать нас и (если применимо)
        наших учредителей, директоров, агентов и сотрудников, от любых претензий
        или требований, включая юридические расходы, от какой-либо третьей
        стороны в связи с нарушением Вами настоящего Соглашения или нарушением
        Вами любого закона или прав третьей стороны.
      </p>
      <h2>11. Интеллектуальная собственность</h2>
      <p>
        11.1. Исходя из условий настоящего Соглашения, если Вы создали Аккаунт
        Сервис предоставляет Вам ограниченную лицензию на доступ для
        использования Сервиса.
        <br />
        11.2. Вы не должны получать доступ (или пытаться получить доступ) к
        Сервису с помощью любых средств, кроме предоставленного интерфейса, и
        обязуетесь не использовать информацию, полученную на Сервисе в
        каких-либо иных целях, кроме тех целей, для которых она была доступна.
        <br />
        11.3. Вы соглашаетесь не использовать несколько устройств/браузеров для
        доступа к Сервису одновременно.
        <br />
        11.4. Вы соглашаетесь не использовать Сервис для предоставления
        каких-либо товаров или услуг.
        <br />
        11.5. Запрещается продавать, воспроизводить, распространять, изменять,
        демонстрировать, публично исполнять, создавать производные работы на
        основе любого контента, расположенного на Сервисе, делать репост или
        каким-либо иным образом использовать любой контент Сервиса любым
        способом в любых общественных или коммерческих целях без
        предварительного письменного согласия с нами.
        <br />
        11.6. Вы не должны использовать какой-либо контент Сервиса на любом
        другом веб-сайте или в сетевой компьютерной среде для любых целей, кроме
        вашего собственного просмотра без предварительного письменного согласия
        с нами.
        <br />
        11.7. Запрещается предоставлять данные своей учетной записи третьим
        лицам для совместного использования Сервиса с одного аккаунта.
        <br />
        11.8. Вы не должны пытаться вскрывать, модифицировать, адаптировать,
        переводить, подготавливать производные работы, декомпилировать, пытаться
        вмешиваться в работу или иным образом пытаться получить исходный код из
        любой части Сервиса.
        <br />
        11.9. Вы не будете использовать доступ к Сервису для создания
        аналогичного сервиса или приложения, или публикации какой-либо
        презентации или любого обзора, теста или анализа, относящегося к
        Сервису.
        <br />
        11.10. Мы сохраняет все права, в том числе авторские права и законные
        интересы в отношении интеллектуальной собственности объектов,
        расположенных на Сервисе.
        <br />
        11.11. Логотипы и названия Сервиса являются товарными знаками нашей
        компании и могут быть зарегистрированы в определенных юрисдикциях.
        <br />
        11.12. За исключением случаев, явно оговоренных в настоящем Соглашении,
        ничто в Соглашении не дает исключительной лицензии ни по одному из прав
        нашей компании или каких-либо прав на интеллектуальную собственность
        третьей стороны, будь то путем эстоппеля, импликации или иным образом.
      </p>
      <h2>12. Мобильные устройства</h2>
      <p>
        12.1. При использовании нашего мобильного приложения, имейте в виду, что
        Вашим оператором будут по-прежнему применяться обычные тарифы и сборы,
        за передачу данных. Мобильное приложение может не содержать некоторые
        функциональные возможности, доступные на сайте.
      </p>
      <h2>13. Заключительные положения</h2>
      <p>
        13.1. Если какое-либо из этих положений будет признано недействительным
        или не имеющим законной силы, это условие будет считаться отделенным от
        остальных и не повлияет на действительность и законную силу любых
        оставшихся положений.
        <br />
        13.2. Настоящие условия регулируются законодательством Российской
        Федерации, и любой спор между сторонами разрешается исключительно в
        судах Российской Федерации.
        <br />
        13.3. Мы не продаем, не сдаем в аренду или иным образом не передаем Вашу
        личную информацию третьим лицам, за исключением случаев, описанных в
        Политике конфиденциальности, например, когда мы считаем, что мы обязаны
        делать это по закону.
        <br />
        13.4. Заголовки предназначены только для справочных целей и никоим
        образом не определяют, не ограничивают, не толкуют и не описывают сферу
        или масштаб любого раздела.
        <br />
        13.5. Любое наше бездействие в отношении нарушений Вами или другими
        лицами настоящего Соглашения не отменяет нашего права действовать в
        отношении последующих или подобных нарушений настоящего Соглашения.
        <br />
        13.6. Принимая эти правила, Пользователь соглашается с тем, что он
        прочитал и понял все положения, перечисленные в настоящем Соглашении.
      </p>
      <h2>14. Свяжитесь с нами</h2>
      <p>
        14.1. Если Вы хотите сообщить о нарушении Соглашения, задать какие-либо
        вопросы или Вам необходима помощь, обратитесь в службу поддержки
        Пользователей по адресу:
      </p>
      <p>
        <b>Email:</b> info@workbet.info
      </p>
    </>
  )
}
