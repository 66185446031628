import { Link } from "react-router-dom"

export const AboutEn = () => {
  return (
    <>
      <h1 className="main__title">About the Project</h1>
      <p>
        Recently, services that analyze the odds offered by various bookmakers
        have become particularly relevant. This approach is the only one that
        claims to be a guaranteed win method for earning on sports betting. The
        collected data is analyzed for the presence of arbitrage situations
        (surebets), and the resulting list is immediately provided to our
        clients. We help thousands of our clients gain an advantage over
        bookmakers, enabling them to earn a stable income from betting.
      </p>
      <b>
        We are not a bookmaker, do not organize gambling, and do not promote it.
      </b>
      <p>
        <b>WORKBET.INFO</b> is a scanner of bookmaker surebets (arbitrage
        situations) on sports markets.
      </p>
      <p>
        <b>Bookmaker surebets</b> arise from different assessments of the
        probability of a particular outcome by different bookmakers.
      </p>
      <p>
        <b>Warning:</b> The <b>Workbet.info</b> service is not a financial
        instrument, does not provide services for managing funds raised from
        third parties, and is not a bookmaker or a gambling platform.
      </p>
      <p>
        Before using the service, please read the{" "}
        <Link to="/terms">User Agreement</Link> and the{" "}
        <Link to="/privacy">Privacy Policy</Link>.
      </p>
      <p>Welcome!</p>
      <p>
        For any questions, please write to{" "}
        <a href="mailto:info@workbet.info">info@workbet.info</a>.
      </p>
      {/*<b>Contacts:</b>*/}
      {/*<p>*/}
      {/*    <b>Organization Name:</b> IP Rabkov I.G.<br />*/}
      {/*    <b>Address:</b> Russia, 603155, Nizhny Novgorod, P.O. Box 180<br />*/}
      {/*    <b>TIN (INN):</b> 526005276440<br />*/}
      {/*    <b>Email:</b> info@workbet.info*/}
      {/*</p>*/}
    </>
  )
}