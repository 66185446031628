import { Link } from "react-router-dom"

export const AboutRu = () => {
  return (
    <>
      <h1 className="main__title">О проекте</h1>
      <p>
        В последнее время сервисы анализа коэффициентов линий разных букмекеров
        приобрели особую актуальность. Данный способ единственный претендует на
        звание беспроигрышного метода заработка на ставках. Собранные данные
        анализируются на наличие арбитражных ситуаций (вилок), список которых
        моментально предоставляется нашим клиентам. Мы помогаем тысячам наших
        клиентов получить преимущество над букмекерскими конторами, благодаря
        которому они имеют стабильный доход на ставках.
      </p>
      <b>
        Мы не являемся букмекером, не занимается организацией азартных игр и не
        пропагандируем их.
      </b>
      <p>
        <b>WORKBET.INFO</b> это сканер букмекерских вилок (арбитражных ситуаций)
        на спортивных рынках.
      </p>
      <p>
        <b>Букмекерские вилки</b> возникают из-за разной оценки вероятности того
        или иного исхода у разных букмекерских контор.
      </p>
      <p>
        <b>Предупреждение:</b> сервис <b>Workbet.info</b> не является финансовым
        инструментом, не предоставляет услуг по управлению средствами,
        привлеченными от третьих лиц, и не является букмекерской конторой или
        азартной игрой.
      </p>
      <p>
        Перед использованием сервиса, прочитайте{" "}
        <Link to="/terms">Пользовательское соглашение</Link> и{" "}
        <Link to="/privacy">Политику приватности</Link>, пожалуйста.
      </p>
      <p>Добро пожаловать!</p>
      <p>
        По всем вопросам просим писать на почту{" "}
        <a href="mailto:info@workbet.info">info@workbet.info</a>.
      </p>
      {/*<b>Контакты:</b>*/}
      {/*<p>*/}
      {/*    <b>Название организации:</b> ИП Рабков И.Г.<br />*/}
      {/*    <b>Адрес:</b> Россия, 603155, Нижний Новгород, А\Я 180<br />*/}
      {/*    <b>ИНН:</b> 526005276440<br />*/}
      {/*    <b>Email:</b> info@workbet.info*/}
      {/*</p>*/}
    </>
  )
}
