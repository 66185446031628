import { Link } from "react-router-dom"

export const RefundEn = () => {
  return (
    <>
      <h1 className="main__title">Refund Policy</h1>
      <h2>Terms</h2>
      <p>
        If a customer is dissatisfied with the quality of the services provided
        by the service, they are entitled to request a refund of the
        subscription purchase within 3 days from the date of purchase.
      </p>
      <p>
        Exceptions include cases of violation of the{" "}
        <Link to="/terms">User Agreement</Link> and the{" "}
        <Link to="/privacy">Privacy Policy</Link>. In such cases, the refund
        program does not apply.
      </p>
      <p>Multiple refunds are granted at the discretion of the administration.</p>
      <h2>Details and Timeframes</h2>
      <p>
        Funds are returned to the payment details used for the original payment.
        If the purchase was paid by credit/debit card, the refund will be
        returned to the card used for payment.
      </p>
      <p>
        The refund period may take up to 30 calendar days from the moment the
        service is disabled for the user, depending on the chosen payment
        method.
      </p>
      <h2>Non-Standard Situations</h2>
      <p>
        In the event of technical issues (double charging, “hang-up,” prolonged
        payment processing), please contact us at the details provided below.
      </p>
      <h2>Instructions</h2>
      <p>
        To request a refund, please contact our manager by email at:{" "}
        <span><a href="mailto:info@workbet.info">info@workbet.info</a></span> for further instructions.
      </p>
      <p>The refund request review period is 1–2 business days.</p>
    </>
  )
}