export const LegalRequestsEn = () => {
  return (
    <>
      <h1 className="main__title">Legal Requests</h1>
      <h2>Question</h2>
      <p>Should I be worried, and what should I do?</p>
      <h2>Briefly</h2>
      <p>
        You won’t miss the moment when you should be worried. The fewer the
        surebettors, the better it is for surebettors.
      </p>
      <h2>In Detail</h2>
      <p>
        When considering legal issues, one must take into account the specifics
        involved. On the one hand, there is legal precedent, meaning that new
        cases are considered in the manner similar cases were handled in the
        past. On the other hand, there is subjective interpretation of the law:
        given sufficient desire and resources, one can either justify or
        condemn, in accordance with the capabilities of prosecutors or defense
        attorneys. A precedent can also be created, paving the way for similar
        cases to be processed in an assembly-line fashion.
      </p>
      <p>
        If the government decides to regulate surebettors, it would most likely
        begin with exemplary punishments of well-known individuals or
        organizations in this field (for instance, requiring scanners to
        disclose players income reports). Various areas of online income
        generation have come under government scrutiny once they became
        sufficiently widespread to generate significant profit. For example,
        traffic arbitrage or freelancing. Therefore, never forget: money loves
        silence.
      </p>
    </>
  )
}