export const StartWinningEn = () => {
  return (
    <>
      <h1 className="main__title">Start Winning</h1>
      <p>
        Initially, every beginning “surebettor” (arbitrage bettor) needs to do
        the following:
      </p>
      <ul>
        <li>Decide on the size of their initial bankroll.</li>
        <li>Choose the “working” bookmakers.</li>
        <li>
          Decide which type of arbitrage situations to focus on (prematch or
          live).
        </li>
        <li>Configure the surebet scanner output.</li>
        <li>Choose a betting strategy.</li>
        <li>Allocate time for this work.</li>
      </ul>
      <h2>Deciding on the size of the initial bankroll</h2>
      <p>
        A starting amount of 200–300 euros, evenly distributed among your
        “working” bookmaker accounts, will be enough for a successful start.
        However, it’s important to understand that the larger your bankroll,
        the higher your potential profit. In the early stages, it is quite
        realistic to earn around 45–50% of your bankroll every month. Later on,
        this percentage may slightly decrease as your bankroll grows.
      </p>
      <h2>Choose the “working” bookmakers</h2>
      <p>
        Register with them, complete verification if necessary, and deposit
        funds into your accounts.
      </p>
    </>
  )
}